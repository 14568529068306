import React from "react";

import { Link, useNavigate } from "react-router-dom";
import { StyledOfferModal } from "./privacypolicy.styles";

const PrivacyPloicyModal = (setOpen) => {
  const navigate = useNavigate();

  return (
    <StyledOfferModal>
      <p>
        Hos PayBlisss værner vi om dine personlige oplysninger. Denne
        fortrolighedspolitik forklarer, hvordan vi indsamler, bruger og
        beskytter dine data.
      </p>
      <p>
        <strong>Indsamling af oplysninger</strong> Vi indsamler personlige
        oplysninger som navn, e-mailadresse og betalingsoplysninger, når du
        registrerer dig eller foretager køb.
      </p>
      <p>
        <strong>Brug af oplysninger</strong> Vi bruger dine oplysninger til at
        behandle transaktioner, forbedre vores tjenester og kommunikere med dig
        om opdateringer.
      </p>
      <p>
        <strong>Beskyttelse af data</strong> Vi implementerer
        sikkerhedsforanstaltninger for at beskytte dine personlige data mod
        uautoriseret adgang.
      </p>
      <p>
        <strong>Deling af oplysninger</strong> Vi deler ikke dine personlige
        oplysninger med tredjepart uden dit samtykke, medmindre det er
        nødvendigt for at overholde loven.
      </p>
      <p>
        <strong>Dine rettigheder</strong> Du har ret til at få adgang til, rette
        eller slette dine personlige oplysninger. Kontakt os via vores
        kontaktformular for at udøve disse rettigheder.
      </p>
      <p>
        <strong>Cookies</strong> Vi bruger cookies til at forbedre
        brugeroplevelsen på vores hjemmeside.
      </p>

      <p>
        <strong>Kontakt os</strong> Hvis du har spørgsmål om vores
        fortrolighedspolitik, kontakt os på{" "}
        <Link to="mailto:support@payblisss.com" className="mail">
          support@payblisss.com
        </Link>
        .
      </p>
    </StyledOfferModal>
  );
};

export default PrivacyPloicyModal;
