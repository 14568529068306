import React from "react";
import InfoSection from "../components/InfoSection";
import RequirementHero from "../components/RequirementHero";
import Offer from "../components/Checkout/Offer";

export const AllInOne = () => {
  return (
    <>
      <RequirementHero
        minHeight
        heading={
          <>
            <span>Alt-i-et</span>
            <br /> kassesystem!
          </>
        }
      />
      <Offer para1="Dyk ned i PayBliss's alt-i-én-kassesystem: en verden, hvor premium design og brugervenlighed går hånd i hånd. Med dette system får du ikke kun en ekstraordinær brugergrænseflade, der imponerer med sin elegance og intuitive navigation, men også en altomfattende pakke, der inkluderer al nødvendig hardware og software. Fra kvitteringsprintere til avancerede salgsfunktioner – alt er inkluderet for at sikre en innovativ og effektiv håndtering af dine daglige transaktioner. Oplev den ultimative kombination af funktionalitet og stil, skabt til at transformere din virksomhedsdrift og kundeoplevelser." />
      <InfoSection styled nopaddingTop />
    </>
  );
};
