import React from "react";
import { StyledOffer } from "./Offer.styles";

const Offer = ({ img, title, offerHeading, para1, para2, para3, para4 }) => {
  return (
    <StyledOffer>
      <div className="container">
        {img && (
          <figure className="offerImageWrapper">
            <img src={img} alt="offerImg" />
          </figure>
        )}
        {title && <h2>{title}</h2>}
        {offerHeading && <span className="offer">{offerHeading}</span>}
        <p>{para1}</p>
        {para2 && <p>{para2}</p>}
        {para3 && <p>{para3}</p>}
        {para4 && <p>{para4}</p>}
      </div>
    </StyledOffer>
  );
};

export default Offer;
