import styled from "styled-components";

export const Error = styled.span`
  display: block;
  color: var(--danger);
  min-height: 26px;
  height: auto;
  opacity: 1;
  font-size: 12px;
  line-height: 16px;
  padding-top: 3px;
  &:first-letter {
    text-transform: uppercase;
  }
`;

export const InputHolder = styled.div`
  @media (min-width: 576px) {
    position: relative;
  }
  @media (max-width: 575px) {
    position: ${({ $searchField }) => !$searchField && "relative"};
  }
`;
export const StyledFormGroup = styled.div`
  margin-bottom: ${({ $invalid, noMargin }) =>
    $invalid || noMargin ? "0px" : "10px"};
  @media (min-width: 768px) {
    margin-bottom: ${({ $invalid, noMargin }) =>
      $invalid || noMargin ? "0px" : "20px"};
  }
  /* position: relative; */
  font-family: "Outfit", sans-serif;
  width: 100%;
  &:nth-last-child(1) {
    margin-bottom: 0;
  }
`;
