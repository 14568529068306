import React from "react";
import { StyledRequirementHero } from "./RequirementHero.styles";

const RequirementHero = ({ minHeight, heading, subtitle }) => {
  return (
    <StyledRequirementHero minHeight={minHeight}>
      <div className="container">
        <h1>{heading}</h1>
        {subtitle && <span className="subtitle">{subtitle}</span>}
      </div>
    </StyledRequirementHero>
  );
};

export default RequirementHero;
