import React from "react";
import RequirementHero from "../components/RequirementHero";
import Offer from "../components/Checkout/Offer";

const LegalRequirement = () => {
  return (
    <div>
      <RequirementHero
        heading={
          <>
            Overholder du den nye
            <br /> <span>lovgivning?</span>
          </>
        }
        subtitle="Med PayBlisss' kassesystem gør du det!"
      />
      <Offer
        title={
          <>
            PayBlisss alt-i-et POS-system opfylder <br /> de nye lovkrav
          </>
        }
        para1="Med PayBlisss alt-i-én POS-system er din virksomhed ikke kun klar til fremtiden, men også fuldt ud i overensstemmelse med de nye strenge lovkrav, der træder i kraft fra 1. januar 2024."
        para2="Den nye lovgivning pålægger alle relevante virksomheder at bruge et digitalt salgsregistreringssystem, der kan dokumentere og rapportere salgsdata præcist og gennemskueligt. Vores system leverer dette med en brugervenlig grænseflade, der gør overgangen ikke kun juridisk bindende, men også nem. Ikke nok med det, du får al den nødvendige hardware og software til ubesværet integration."
        para3="‍ Bestil kassesystem nu, og sørg for et nummer i køen, så din virksomhed fortsætter med at blomstre uden afbrydelser."
        para4="For mere detaljeret information om de specifikke krav, kan du læse mere om denne attreasure."
      />
    </div>
  );
};

export default LegalRequirement;
