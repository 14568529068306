import styled, { css } from "styled-components";

export const StyledHeader = styled.div`
  position: fixed;
  top: 0px;
  left: 0;
  right: 0;
  z-index: 9;
  ${({ $bg }) =>
    $bg &&
    css`
      background: var(--white);
      box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
        rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
    `}
  ${({ $showNav }) =>
    $showNav &&
    css`
      background: var(--white);
    `}

  transition: all 0.3s ease-in-out;
  .logoWrapper {
    max-width: 120px;
    @media screen and (min-width: 991px) {
      max-width: 146px;
    }
    img {
      max-width: 100%;
      height: auto;
    }
  }
  .container {
    padding: 15px;
    transition: all 0.3s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: space-between;
    ${({ $bg }) =>
      $bg &&
      css`
        padding: 15px;
      `}
    @media screen and (min-width: 991px) {
      padding: 30px 15px;
      ${({ $bg }) =>
        $bg &&
        css`
          padding: 20px 15px;
        `}
    }
  }
  .NavLinkWrapper {
    position: fixed;
    top: 61px;
    left: 0;
    right: 0;
    padding: 20px;
    background: var(--white);
    transition: all 0.3s ease-in-out;
    transform: translateY(${({ $showNav }) => ($showNav ? "0" : "-200%")});
    z-index: -1;

    @media screen and (min-width: 991px) {
      width: 100%;
      position: static;
      max-width: 650px;
      display: flex;
      align-items: center;
      background: transparent;
      padding: 0;
      transform: translateY(0);
      z-index: 0;
    }

    ul {
      position: relative;
      width: 100%;
      list-style: none;
      font-size: 16px;
      font-weight: 300;
      line-height: 20px;
      text-align: center;
      padding: 0 15px 0 0;
      @media screen and (min-width: 991px) {
        max-width: 300px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 5px;
      }
      li {
        cursor: pointer;
        &:hover {
          color: #36469a;
        }
        @media screen and (max-width: 990px) {
          margin-bottom: 25px;
        }
      }
      &::before {
        content: "";
        z-index: 1;
        position: absolute;
        top: 0;
        bottom: 0;
        width: 1px;
        right: 0;
        background: #cdcdcd;
        @media screen and (max-width: 990px) {
          display: none;
        }
      }
      a {
        color: #313131;
        font-size: 16px;
        font-weight: 300;
        line-height: 20px;
        transition: 0.3s all ease-in-out;
        &:hover {
          color: #36469a;
        }
      }
      .active {
        font-size: 16px;
        font-weight: 600;
        line-height: 20.16px;
        text-align: center;
        color: #36469a;
        position: relative;
        &::before {
          content: "";
          z-index: 1;
          position: absolute;
          bottom: -5px;
          height: 1.5px;
          left: 0;
          width: 80%;
          background: #36469a;
        }
      }
    }
    .Cta {
      width: 100%;
      max-width: 184px;
      margin: 0 auto;
      color: #313131;

      @media screen and (min-width: 992px) {
        max-width: 100%;
        display: flex;
        gap: 16px;
        padding-left: 15px;
      }

      .whatsApp {
        color: #313131;
        border: 1px solid #36469a;
        border-radius: 60px;
        font-size: 12px;
        font-weight: 300;
        line-height: 16px;
        padding: 8px 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 5px;
        margin-bottom: 15px;

        @media screen and (min-width: 991px) {
          max-width: 126px;
          margin-bottom: 0;
        }
        &:hover {
          color: #36469a;
        }
      }
    }
  }
  .hamburger {
    color: var(--black);
    font-size: 26px;
    line-height: 1;
    @media screen and (min-width: 991px) {
      display: none;
    }
  }
`;
