import React from "react";
import img01 from "../../assets/images/img03.png";
import img02 from "../../assets/images/img05.png";
import img03 from "../../assets/images/img04.png";
import img04 from "../../assets/images/img06.png";
import img05 from "../../assets/images/img07.png";
import rocketIcon from "../../assets/images/icon-rocket.svg";
import { InfoSectionStyle, TextHolder, ColumnWrap } from "./InfoSection.styles";
import Button from "../Button";
import { Link } from "react-router-dom";

function InfoSection({ nopaddingTop, styled }) {
  return (
    <>
      <InfoSectionStyle $nopaddingTop={nopaddingTop} styled={styled}>
        <div className="container">
          <TextHolder>
            <h2>
              Du får alt hvad du
              <br /> behøver!
            </h2>
            <p>
              Når vi siger alt-i-én, mener vi ALT-i-én. Med vores kassesystem
              behøver du ikke andet for at håndtere kundebetalinger.
            </p>
            <span className="line">----------------------</span>
            <p>
              Med PayBlisss's <Link href="/">alt-i-ét kassesystem</Link> får du
              alt, hvad du behøver for at modtage betalinger med stil. Vores
              lækre og intuitive brugerflade gør det til en leg at betjene
              kunder, så du kan fokusere på det, der virkelig tæller - din
              forretning. Oplev betalingsglæde uden besvær med PayBlisss.
            </p>
            <div className="btn-holder">
              <Link to="/order-checkout" className="button">
                <Button className="seeMore" outline>
                  Se mere!
                </Button>
              </Link>
              <Link to="/order-checkout" className="button">
                <Button>
                  Bestil dit kassesystem!
                  <img src={rocketIcon} alt="rocket" />
                </Button>
              </Link>
            </div>
          </TextHolder>
          <ColumnWrap>
            <div className="column hardware">
              <div className="text-box">
                <strong className="title">Hardware</strong>
                <p>
                  Du får alt det hardware, du skal bruge til at drive din
                  virksomhed og overholde loven.
                </p>
                <ol className="list">
                  <li>15,6" berøringsskærm</li>
                  <li>Indbygget betalingsterminal (NFC)</li>
                  <li>Pengeskuffe</li>
                  <li>Bonprinter</li>
                  <li>Scanner</li>
                </ol>
                <span className="text">
                  <strong>Pris: 20.00 Kr.</strong> Plus moms Betales ved
                  levering & opsætning!
                </span>
              </div>
              <div className="img-box">
                <img src={img01} width={242} alt="img description" />
              </div>
            </div>
            <div className="column software">
              <div className="text-box">
                <strong className="title">Software</strong>
                <p>
                  Tjen dine kunder som en leg med en af ​​markedets mest
                  brugervenlige PoS-software
                </p>
                <ol className="list">
                  <li>Brugervenlig grænseflade</li>
                  <li>Overhold loven</li>
                  <li>Kun 1,49% i kortgebyrer</li>
                  <li>Skræddersyet til din butikstype</li>
                </ol>
                <span className="text">
                  <strong>Pris: 300 Kr</strong> plus moms om måneden. 12
                  måneders binding
                </span>
              </div>
              <div className="img-box">
                <img src={img02} width={220} alt="img description" />
              </div>
            </div>
            <div className="column setup">
              <div className="text-box">
                <strong className="title">Opsætning</strong>
                <p>
                  Vi sætter hele kassesystemet op uden ekstra omkostninger og
                  sørger for, at det hele fungerer optimalt.
                </p>
                <ol className="list">
                  <li>Levering</li>
                  <li>Opsætning</li>
                  <li>Vi kører ikke før det virker</li>
                </ol>
              </div>
              <div className="img-box">
                <img src={img03} width={200} alt="img description" />
              </div>
            </div>
            <div className="col-wrap">
              <div className="column prices">
                <div className="text-box">
                  <strong className="title">Priser</strong>
                  <p>
                    Gennemsigtig og uden småt. Du ved præcis hvordan og hvor
                    meget det koster.
                  </p>
                  <span className="text">
                    <strong>20.00 Kr</strong> plus moms for kassesystemet
                  </span>
                  <span className="text">
                    <strong>399 Kr</strong> plus moms om måneden for software og
                    teknisk support
                  </span>
                </div>
                <div className="img-box">
                  <img src={img04} width={118} alt="img description" />
                </div>
              </div>
              <div className="column support">
                <div className="text-box">
                  <strong className="title">Teknisk support</strong>
                  <p>Tilgængelig alle ugens dage 24/7.</p>
                  <ol className="list">
                    <li>WhatsApp</li>
                    <li>Telefon</li>
                    <li>Online</li>
                  </ol>
                </div>
                <div className="img-box">
                  <img src={img05} width={125} alt="img description" />
                </div>
              </div>
            </div>
          </ColumnWrap>
        </div>
      </InfoSectionStyle>
    </>
  );
}

export default InfoSection;
