import React from "react";
import img01 from "../../assets/images/img02.png";
import { ChckoutStyle, TextBox, ImgBox } from "./ChckoutBlock.styles";
import { Link } from "react-router-dom";

function ChckoutBlock() {
  return (
    <>
      <ChckoutStyle>
        <div className="container">
          <TextBox>
            <h2>Med et PayBliss kassesystem gør du det!</h2>
            <ul className="info-list">
              <li>SAF-T FIL</li>
              <li>OCES3 CERTIFICERING</li>
              <li>ISEA3000 CERTIFICERT</li>
              <li>OG AAAALLE ANDRE JURIDISKE KRAV</li>
            </ul>
            <div className="box">
              <p>
                PayBlisss bringer dig sikkert ind i den nye digitale tidsalder,
                hvor du er fri for bekymringer om bøder og uanmeldte besøg fra
                skattemyndighederne.
              </p>
              <p>
                Med PayBlisss kassesystem overholder du alle de nye krav til
                digitale kasseapparater i{" "}
                <Link href="/">momsbekendtgørelsens § 62,</Link> som har været
                gældende siden 1. januar 2024.
              </p>
            </div>
            <Link to="" className="link">
              læs mere om lovkravene!
            </Link>
          </TextBox>
          <ImgBox>
            <img src={img01} width={200} alt="img description" />
          </ImgBox>
        </div>
      </ChckoutStyle>
    </>
  );
}

export default ChckoutBlock;
