import React from "react";
import HeroSection from "../components/Checkout/HeroSection";
import Offer from "../components/Checkout/Offer";
import Contact from "../components/Checkout/Contact";
import offerImg from "../assets/images/offerImg.svg";

const OrderCheckout = () => {
  return (
    <>
      <HeroSection />
      <Offer
        img={offerImg}
        title="Kampagnetilbud!"
        offerHeading="Grib chancen fra april til oktober 2024 - Få dit kassesystem, 100 % gratis"
        para1="I hele fra april til oktober 2024 tilbyder vi en unik chance for at revolutionere din virksomhed med vores avancerede kassesystem - helt gratis. Ja, du hørte rigtigt: GRATIS!"
        para2="Tag skridtet og oplev forskellen med vores kassesystem gratis i 30 dage. Ingen bindinger, ingen skjulte gebyrer. Hvis du beslutter dig for at fortsætte efter prøveperioden, kan du gøre dette til en fast lav månedlig pris på blot 399 kr. - uden merpris."
        para3="I denne særlige kampagneperiode får du mulighed for at spare hele 20.000 kr., hvilket svarer til systemets fulde værdi. Lad ikke denne utrolige mulighed slippe igennem dine hænder. Opdag, hvordan vores kassesystem kan gøre en forskel for din virksomhed allerede i dag!."
      />
      <Contact />
    </>
  );
};

export default OrderCheckout;
