import styled from "styled-components";

export const StyledOffer = styled.div`
  font-weight: 300;
  text-align: left;
  color: #313131;
  margin: -80px 0 80px;
  padding: 0 15px;

  @media screen and (min-width: 768px) {
    font-size: 18px;
    line-height: 22px;
  }

  h2 {
    text-transform: capitalize;
    margin: 0 0 30px;
  }
  .offerImageWrapper {
    margin-bottom: 10px;
    max-width: 30px;
    @media screen and (min-width: 768px) {
      max-width: 60px;
      margin-bottom: 20px;
    }
  }
  .offer {
    display: block;
    margin-bottom: 26px;
    max-width: 520px;
    background: #36469a1a;
    border-radius: 20px;
    padding: 14px 26px;
    color: var(--blue);
    @media screen and (min-width: 768px) {
      border-radius: 60px;
    }
  }
  .container {
    background: var(--white);
    border-radius: 30px;
    padding: 20px;
    box-shadow: 0px 132px 37px 0px rgba(0, 0, 0, 0),
      0px 84px 34px 0px rgba(0, 0, 0, 0.01),
      0px 47px 28px 0px rgba(0, 0, 0, 0.03),
      0px 21px 21px 0px rgba(0, 0, 0, 0.04),
      0px 5px 12px 0px rgba(0, 0, 0, 0.05);

    @media screen and (min-width: 991px) {
      padding: 40px;
    }
  }
`;

export const ContactStyles = styled.div`
  margin-bottom: 100px;
  .container {
    @media screen and (min-width: 991px) {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      gap: 20px;
    }
  }
  .textWrapper {
    .col {
      text-align: left;
      margin-bottom: 40px;
      width: 100%;
      font-size: 300;
      @media screen and (min-width: 991px) {
        max-width: 502px;
      }
      p {
        font-weight: 300;
      }
      .included {
        display: flex;
        align-items: center;
        gap: 10px;
        margin-bottom: 15px;
        p {
          font-size: 300;
        }
      }
      .title {
        display: block;
        text-transform: capitalize;
        margin-bottom: 15px;
        font-size: 24px;
        line-height: 28px;
        font-weight: 500;
        @media screen and (min-width: 991px) {
          margin-bottom: 30px;
          font-size: 32px;
          line-height: 36px;
        }
      }
    }
  }
  .formWrapper {
    max-width: 100%;
    width: 100%;
    box-shadow: 0px 341px 96px 0px rgba(0, 0, 0, 0),
      0px 218px 87px 0px rgba(0, 0, 0, 0.01),
      0px 123px 74px 0px rgba(0, 0, 0, 0.03),
      0px 55px 55px 0px rgba(0, 0, 0, 0.04),
      0px 14px 30px 0px rgba(0, 0, 0, 0.05);
    background: var(--white);
    border-radius: 30px;
    padding: 30px 15px;
    @media screen and (min-width: 991px) {
      max-width: 450px;
      padding: 30px;
    }
    @media screen and (min-width: 1199px) {
      max-width: 585px;
    }
  }
  .privacy {
    display: block;
    padding-top: 20px;
    font-size: 14px;
    line-height: 18px;
    font-weight: 300;
    text-align: left;
    @media screen and (min-width: 768px) {
      font-size: 16px;
      line-height: 20px;
    }
  }
`;

export const StyledOfferModal = styled.div`
  font-size: 16px;
  line-height: 20px;
  font-weight: 300;
  text-align: left;
  ul{
    list-style: disc;
    padding-left:25px ;
  }
  @media screen and (min-width: 768px) {
    font-size: 18px;
    line-height: 22px;
  }
  p {
    margin-bottom: 26px;
  }
`;
