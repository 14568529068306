import { BrowserRouter, Route, Routes } from "react-router-dom";
import GlobalStyles, {
  StyledToastContainer,
} from "./styles/GlobalStyles.styles";
import { Home } from "./pages/Home";
import Layout from "./components/Layout";
import OrderCheckout from "./pages/OrderCheckout";
import LegalRequirement from "./pages/LegalRequirement";
import { AllInOne } from "./pages/AllInOne";

const App = () => {
  return (
    <BrowserRouter>
      <GlobalStyles />
      <Layout>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/order-checkout" element={<OrderCheckout />} />
          <Route path="/Lovkrav" element={<LegalRequirement />} />
          <Route path="/alt-i-en" element={<AllInOne />} />
          {/* <Routes path="*" element={<PageNotFound />} /> */}
        </Routes>
      </Layout>
      <StyledToastContainer />
    </BrowserRouter>
  );
};
export default App;
