import styled, { css } from "styled-components";

export const StyledRequirementHero = styled.section`
  width: 100%;
  min-height: 370px;
  padding: 90px 0 130px;
  text-align: center;
  text-align: center;
  background: linear-gradient(to bottom, #ebfaff 0%, #e6e4ff 100%);

  @media (min-width: 768px) {
    min-height: 440px;
    padding: 115px 0 100px;
  }
  @media (min-width: 992px) {
    min-height: 500px;
    padding: 165px 0 100px;
  }
  @media (min-width: 1200px) {
    min-height: 600px;
    padding: 195px 0 100px;
  }

  ${({ minHeight }) =>
    minHeight &&
    css`
      @media (min-width: 1200px) {
        min-height: 520px;
        padding: 175px 0 100px;
      }
    `}

  h1 {
    text-transform: capitalize;
  }

  .subtitle {
    display: block;
    font-size: 22px;
    line-height: 26px;
  }
`;
