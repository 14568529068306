import React from "react";

import { Link, useNavigate } from "react-router-dom";
import { StyledOfferModal } from "./conditionpolicy.styles";

const ConditionPloicyModal = (setOpen) => {
  const navigate = useNavigate();

  return (
    <StyledOfferModal>
      <p>
        <strong>Introduktion</strong> Velkommen til PayBlisss. Ved at bruge
        vores hjemmeside accepterer du disse vilkår og betingelser.
      </p>
      <p>
        <strong>Brug af Hjemmesiden</strong> Du må kun bruge vores hjemmeside
        til lovlige formål og i overensstemmelse med gældende lovgivning.
      </p>
      <p>
        <strong>Intellektuel Ejendomsret</strong> Alt indhold på hjemmesiden,
        herunder tekst, billeder og logoer, tilhører PayBlisss og er beskyttet
        af ophavsret.
      </p>
      <p>
        <strong>Brugeroplysninger</strong> Du er ansvarlig for at sikre, at de
        oplysninger, du giver os, er korrekte og ajourførte.
      </p>
      <p>
        <strong>Betalinger</strong> Alle betalinger skal ske via vores sikre
        betalingssystem. Vi accepterer ikke ansvar for tab som følge af
        uautoriserede betalinger.
      </p>
      <p>
        <strong>Ansvarsfraskrivelse</strong> Vi bestræber os på at holde vores
        hjemmeside opdateret og korrekt, men vi giver ingen garantier for
        nøjagtigheden eller fuldstændigheden af indholdet.
      </p>
      <p>
        <strong>Ændringer</strong> Vi forbeholder os retten til at ændre disse
        vilkår og betingelser når som helst. Ændringer vil blive offentliggjort
        på denne side.
      </p>
      <p>
        <strong>Lovvalg</strong> Disse vilkår og betingelser er underlagt og
        fortolkes i overensstemmelse med dansk lovgivning.
      </p>

      <p>
        <strong>Kontakt os</strong> Hvis du har spørgsmål om disse vilkår og
        betingelser, kontakt os på{" "}
        <Link to="mailto:support@payblisss.com" className="mail">
          support@payblisss.com
        </Link>
        .
      </p>
    </StyledOfferModal>
  );
};

export default ConditionPloicyModal;
