import styled, { css } from "styled-components";

export const styles = css`
  border: 1px solid
    ${({ $invalid }) => ($invalid ? "var(--danger)" : "#F1F1F1")};
  background: var(--white);
  outline: none;
  height: 50px;
  padding: 10px 23px;
  width: 100%;
  transition: border var(--animation-speed) ease-in-out;
  color: var(--gray-dark);
  font-size: 14px;
  font-family: var(--base-font-sans-serif);
  font-weight: 400;
  line-height: 18px;
  border-radius: ${({ $straight }) => ($straight ? "6px" : "100px")};
  padding-left: ${({ $prefix }) => $prefix && "2.5rem"};
  padding-right: ${({ $suffix, $button }) => {
    if ($suffix) return "2.5rem";
    if ($button) return "3.6rem";
    return "";
  }};

  ${({ type }) =>
    type === "search" &&
    css`
      transition: all var(--animation-speed) ease-in-out;

      ${({ responsive }) =>
        responsive &&
        css`
          @media (max-width: 767px) {
            position: absolute;
            top: -22px;
            right: 7px;
            z-index: 9;
            box-shadow: 0px 23px 44px rgba(176, 183, 195, 0.3);
            background: var(--white);
            border: 1px solid var(--light);
            opacity: 0;
            visibility: hidden;
            transform: translateX(10px);
            width: 0;
          }
          @media (max-width: 575px) {
            top: 100%;
            left: 0;
            right: 0;
            width: 100%;
          }
        `}

      ${({ openSearch }) =>
        openSearch &&
        css`
          @media (max-width: 767px) {
            opacity: 1;
            visibility: visible;
            transform: translateX(0);
            width: 350px;
          }
          @media (max-width: 575px) {
            transform: translateY(0);
            width: 100%;
          }
        `}
    `}

  ${({ disabled }) =>
    disabled &&
    css`
      background: var(--light);
      cursor: not-allowed;
      border-color: var(--gray-2);
      color: var(--light-gray);
    `}



  ::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: var(--gray-dark);
  }
  ::-moz-placeholder {
    /* Firefox 19+ */
    color: var(--gray-dark);
  }
  :-ms-input-placeholder {
    /* IE 10+ */
    color: var(--gray-dark);
  }
  :-moz-placeholder {
    /* Firefox 18- */
    color: var(--gray-dark);
  }
`;

export const StyledTextarea = styled.textarea`
  ${styles}
  resize: vertical;
  min-height: 9.375rem;
`;

export const StyledInput = styled.input`
  ${styles}
  &::placeholder {
    color: var(--gray-dark);
    font-weight: 300;
  }
`;
