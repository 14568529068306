import styled from "styled-components";

export const StyledOurPlans = styled.section`
  padding-bottom: 50px;
  @media (min-width: 992px) {
    padding-bottom: 100px;
  }

  h2 {
    text-align: center;
    margin-bottom: 15px;
  }
  .sub-heading {
    display: block;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    margin-bottom: 40px;
  }
  .cards-holder {
    display: block;
    margin-bottom: 40px;
    @media (min-width: 992px) {
      display: flex;
      justify-content: center;
      gap: 20px;
    }

    .cards {
      width: 100%;
      margin-bottom: 30px;
      @media (min-width: 992px) {
        max-width: 576px;
        margin-bottom: 0;
      }
    }

    .specifications {
      width: 100%;
      padding: 20px;
      border-radius: 20px;
      border: 1px solid rgba(205, 205, 205, 0.5);
      background: var(--White, #fff);
      box-shadow: 0px 132px 37px 0px rgba(0, 0, 0, 0),
        0px 84px 34px 0px rgba(0, 0, 0, 0.01),
        0px 47px 28px 0px rgba(0, 0, 0, 0.03),
        0px 21px 21px 0px rgba(0, 0, 0, 0.04),
        0px 5px 12px 0px rgba(0, 0, 0, 0.05);

      @media (min-width: 992px) {
        max-width: 385px;
      }
      @media (min-width: 1200px) {
        max-width: 574px;
      }

      .list {
        display: flex;
        justify-content: space-between;
        position: relative;
        padding-right: 30px;

        .img-holder {
          position: absolute;
          top: 0;
          right: 0;
        }

        &:not(:last-child) {
          margin-bottom: 20px;
        }
      }
    }
  }
  .button {
    display: flex;
    justify-content: center;
  }
`;
