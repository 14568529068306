import React from "react";
import HeroSection from "../components/HeroSection";
import TextHolder from "../components/TextHolder";
import ChckoutBlock from "../components/ChckoutBlock";
import InfoSection from "../components/InfoSection";
import OurPlans from "../components/OurPlans";

export const Home = () => {
  return (
    <>
      <HeroSection />
      <TextHolder />
      <ChckoutBlock />
      <InfoSection />
      <OurPlans />
    </>
  );
};
