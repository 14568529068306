import styled from "styled-components";
import iconTick from "../../assets/images/icon-tick.svg";
import bgImg from "../../assets/images/bg-img01.png";

export const ChckoutStyle = styled.div`
  position: relative;
  overflow: hidden;
  padding: 30px 0;
  font-size: 16px;
  line-height: 20px;
  background: #f5f6fa;
  z-index: 1;

  &::before {
    display: none;
    content: "";
    position: absolute;
    top: 15px;
    right: -80px;
    width: 100%;
    height: 420px;
    background: url(${bgImg}) no-repeat;
    background-position: 100% 50%;
    z-index: -1;

    @media (min-width: 992px) {
      display: block;
    }
  }

  .container {
    @media (min-width: 768px) {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 20px;
    }
  }

  .link {
    display: inline-block;
    vertical-align: top;
    font-weight: 500;
    color: var(--blue);
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
`;

export const TextBox = styled.div`
  max-width: 880px;
  font-weight: 300;

  @media (min-width: 992px) {
    font-size: 18px;
    line-height: 22px;
  }

  h2 {
    margin: 0 0 30px;
    text-transform: capitalize;
  }

  p {
    a {
      color: #313131;
      font-weight: 700;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }

  .info-list {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-flow: wrap;
    align-items: center;
    font-size: 16px;
    line-height: 20px;
    font-weight: normal;
    text-transform: uppercase;

    li {
      position: relative;
      padding: 0 10px 3px 22px;
      margin: 0 0 10px;

      @media (min-width: 768px) {
        padding: 0 22px 5px;
      }
      @media (min-width: 1200px) {
        padding: 0 22px 15px;
      }

      &:first-child {
        &::after {
          display: none;
        }
      }

      &::before {
        content: "";
        position: absolute;
        top: 1px;
        left: 0;
        width: 18px;
        height: 18px;
        border-radius: 50px;
        background: url(${iconTick}) no-repeat;
      }
      &::after {
        display: none;
        content: "";
        position: absolute;
        top: 2px;
        left: -10px;
        width: 1px;
        height: 14px;
        background: #cdcdcd;

        @media (min-width: 768px) {
          display: block;
        }
      }
    }
  }

  .box {
    max-width: 600px;
    margin: 0 0 15px;

    @media (min-width: 768px) {
      margin: 0 0 30px;
    }
  }
`;
export const ImgBox = styled.div`
  max-width: 200px;
  margin: 0 auto;

  @media (min-width: 768px) {
    margin: 0;
  }
`;
