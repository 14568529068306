import styled, { css } from "styled-components";

export const InfoSectionStyle = styled.div`
  position: relative;
  padding: ${({ $nopaddingTop }) => ($nopaddingTop ? "0" : "35px")} 0 35px 0;
  background: var(--white);

  @media (min-width: 768px) {
    padding: ${({ $nopaddingTop }) => ($nopaddingTop ? "0" : "50px")} 0 50px 0;
  }
  @media (min-width: 1200px) {
    padding: ${({ $nopaddingTop }) => ($nopaddingTop ? "0" : "85px")} 0 85px 0;
  }

  .btn-holder {
    display: flex;
    justify-content: center;
    gap: 10px;
    padding: 15px 0 40px;
  }

  ${({ styled }) =>
    styled &&
    css`
      .btn-holder {
        .seeMore {
          display: none;
        }
      }
    `}

  p {
    a {
      color: #313131;
      font-weight: 700;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }
`;

export const TextHolder = styled.div`
  text-align: center;
  max-width: 950px;
  margin: 0 auto;
  font-weight: 300;

  @media (min-width: 992px) {
    font-size: 18px;
    line-height: 22px;
  }

  .line {
    display: block;
    margin: 0 0 10px;

    @media (min-width: 768px) {
      margin: 0 0 20px;
    }
  }

  h2 {
    margin: 0 0 30px;
    text-transform: capitalize;
  }
`;

export const ColumnWrap = styled.div`
  @media (min-width: 992px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }

  .col-wrap {
    @media (min-width: 768px) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 20px;
    }
  }

  .column {
    position: relative;
    overflow: hidden;
    width: 100%;
    font-size: 14px;
    line-height: 18px;
    text-transform: capitalize;
    padding: 20px;
    margin: 0 0 15px;
    border-radius: 20px;
    background: #ebecf5;

    @media (min-width: 768px) {
      min-height: 250px;
      padding: 15px 225px 20px 20px;
    }
    @media (min-width: 992px) {
      margin: 0;
    }

    &.hardware {
      .img-box {
        right: -25px;
      }
    }
    &.software {
      background: #ebfaff;
    }
    &.setup {
      background: #e5f8ef;
    }
    &.prices {
      background: #fff7f7;

      @media (min-width: 768px) {
        padding: 20px 35px 20px 15px;
      }

      .img-box {
        @media (max-width: 1199px) {
          width: 90px;
        }
      }
    }
    &.support {
      background: #f0e5f8;

      @media (min-width: 768px) {
        padding: 20px 15px;
      }

      .img-box {
        @media (max-width: 1199px) {
          width: 90px;
        }
      }
    }

    .title {
      display: block;
      font-size: 22px;
      line-height: 26px;
      font-weight: 500;
      text-transform: capitalize;
      margin: 0 0 10px;

      @media (min-width: 768px) {
        font-size: 26px;
        line-height: 29px;
      }
    }

    ol.list {
      margin: 0 0 0 15px;
      padding: 0;

      li {
        margin: 0 0 5px;
      }
    }

    .text-box {
      max-width: 334px;
      position: relative;

      .text {
        display: block;
        font-size: 12px;
        line-height: 15px;
        margin: 0 0 10px;
      }
    }

    .img-box {
      display: none;
      position: absolute;
      right: 0;
      bottom: 0;

      @media (min-width: 768px) {
        display: block;
      }
    }
  }
`;
