import React from "react";
import Img01 from "../../assets/images/img01.png";
import rocketIcon from "../../assets/images/icon-rocket-dark.svg";
import { HeroStyles } from "./HeroSection.styles";
import Button from "../Button";
import { Link } from "react-router-dom";

function HeroSection() {
  return (
    <>
      <HeroStyles>
        <div className="container">
          <h1>
            Alt-i-et <span>betalingssystem</span> nemt,
            <span>PayBlisss...</span>
          </h1>
          <div className="btn-holder">
            <Link to="/order-checkout" className="button">
              <Button width="230px" variant={"white"}>
                Bestil dit kassesystem!
                <img src={rocketIcon} alt="rocket" />
              </Button>
            </Link>
          </div>
          <div className="img-wrap">
            <img src={Img01} width={1166} alt="img description" />
          </div>
        </div>
      </HeroStyles>
    </>
  );
}

export default HeroSection;
