import styled from "styled-components";
import bgImg from "../../assets/images/bg-strip.png";

export const HeroStyles = styled.div`
  width: 100%;
  padding: 90px 0 0;
  display: flex;
  align-items: center;
  justify-content: end;
  position: relative;
  text-align: center;
  background: linear-gradient(to bottom, #ebfaff 0%, #e6e4ff 100%);

  @media (min-width: 992px) {
    padding: 120px 0 10px;
  }
  @media (min-width: 1200px) {
    min-height: 787px;
    padding: 140px 0 10px;
  }

  &::before {
    height: 60px;
    content: "";
    position: absolute;
    bottom: -10px;
    left: 0;
    right: 0;
    background: url(${bgImg});
    z-index: 5;

    @media (min-width: 768px) {
      height: 100px;
      bottom: -20px;
    }
    @media (min-width: 1200px) {
      height: 130px;
    }
    @media (min-width: 1440px) {
      bottom: -45px;
    }
  }

  h1 {
    text-transform: capitalize;
  }

  .container {
    position: relative;
  }

  .btn-holder {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 0;

    @media (min-width: 768px) {
      padding: 50px 0;
    }
  }

  .img-wrap {
    max-width: 650px;
    margin: 0 auto;

    @media (min-width: 992px) {
      max-width: 800px;
    }
    @media (min-width: 1200px) {
      max-width: 1000px;
    }
    @media (min-width: 1440px) {
      max-width: 1166px;
    }
  }
`;
