import React from "react";

import { Link, useNavigate } from "react-router-dom";
import { StyledOfferModal } from "./cookiepolicy.styles";

const CookiePolicyModal = (setOpen) => {
  const navigate = useNavigate();

  return (
    <StyledOfferModal>
      <p>
        På PayBlisss bruger vi cookies til at forbedre din oplevelse på vores
        hjemmeside. Denne politik forklarer, hvad cookies er, hvordan vi bruger
        dem, og hvordan du kan administrere dine cookie-præferencer.
      </p>
      <p>
        <strong>Hvad er cookies?</strong>
        <br />
        Cookies er små tekstfiler, der gemmes på din enhed, når du besøger en
        hjemmeside. De bruges til at huske dine præferencer og forbedre din
        oplevelse.
      </p>
      <p>
        <strong>Hvordan vi bruger cookies </strong> Vi bruger cookies til at:{" "}
        <br />
        <ul>
          <li>Huske dine loginoplysninger</li>
          <li>Forstå, hvordan du bruger vores hjemmeside</li>
          <li>ilpasse vores tjenester og markedsføring</li>
        </ul>
      </p>

      <p>
        <strong>Typer af cookies vi bruger</strong>
        <br />
        <ul>
          <li>
            <strong>Nødvendige cookies:</strong> Disse er essentielle for, at
            hjemmesiden kan fungere korrekt.
          </li>
          <li>
            <strong>Præferencecookies:</strong> Disse husker dine valg og
            præferencer.
          </li>
          <li>
            <strong>Statistikcookies:</strong> Disse hjælper os med at forstå,
            hvordan besøgende interagerer med vores hjemmeside.
          </li>
          <li>
            <strong>Marketingcookies:</strong> Disse bruges til at spore
            besøgende på tværs af hjemmesider med det formål at vise relevante
            annoncer.
          </li>
        </ul>
      </p>

      <p>
        <strong>Tredjepartscookies</strong> Vi kan også tillade
        tredjepartscookies fra partnere som Google Analytics for at hjælpe os
        med at forbedre vores tjenester.
      </p>

      <p>
        <strong>Administrering af cookies</strong> Du kan administrere eller
        deaktivere cookies i dine browserindstillinger. Vær opmærksom på, at
        deaktivering af cookies kan påvirke funktionaliteten af vores
        hjemmeside.
      </p>

      <p>
        <strong>Kontakt os</strong> Hvis du har spørgsmål om vores
        cookiepolitik, kontakt os på{" "}
        <Link to="mailto:support@payblisss.com" className="mail">
          support@payblisss.com
        </Link>
        .
      </p>
    </StyledOfferModal>
  );
};

export default CookiePolicyModal;
