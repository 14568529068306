import styled, { css } from "styled-components";

export const StyledButton = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: ${({ $gap }) => ($gap ? $gap : "5px")};
  padding: 10px 24px;
  border-radius: 60px;
  font: 400 14px/17px "Outfit";
  /* width: ${({ $width }) => ($width ? $width : "100%")}; */
  min-width: 150px;
  background: var(--blue);
  color: var(--white);
  transition: 0.3s all ease-in-out;

  ${({ $loader, disabled }) =>
    $loader ||
    (disabled &&
      css`
        cursor: not-allowed;
      `)}

  &:hover {
    opacity: 0.85;
    box-shadow: 0px 9px 9px 3px rgba(29, 41, 57, 0.04);
  }

  ${({ $sm }) =>
    $sm &&
    css`
      display: flex;
      align-items: center;
      padding: 12px 15px;
      min-height: 44px;
    `}

  /***** Background-Variants-Start *****/
  ${({ $variant }) =>
    $variant == "white" &&
    css`
      background: var(--white);
      color: var(--blue);
      box-shadow: 0px 3px 10px 0px #0000001a;
      padding: 14px 34px;
    `}
  
  /*****************Background Variants End*********************/


  /*****************Border Variants Start*********************/

  ${({ $outline }) =>
    $outline &&
    css`
      border: 1px solid var(--blue);
      background: transparent;
      color: var(--blue);
    `}
  /*****************Border Variants End*********************/

    .loader {
    width: 17px;
    height: 17px;
    border-radius: 50%;
    display: inline-block;
    border-top: 3px solid #fff;
    border-right: 3px solid transparent;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
  }

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
