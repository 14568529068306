import React from "react";
import headingImg from "../../assets/images/heading-img.png";
import { TextHolderStyle } from "./TextHolder.styles";

function TextHolder() {
  return (
    <>
      <TextHolderStyle>
        <div className="container ">
          {/* <div className="img-holder"> */}
            {/* <img src={headingImg} width="1150" alt="img description" /> */}

            <h2>
              overholder du den nye kasselovgivning af 1.januar 2024?
            </h2>
          {/* </div> */}
        </div>
      </TextHolderStyle>
    </>
  );
}

export default TextHolder;
