import React, { forwardRef } from "react";
import { Error, InputHolder, StyledFormGroup } from "./Field.styles";
import Label from "../Label";
import Input from "../Input";

const defaultProps = {
  type: "text",
};

const Field = forwardRef(
  (
    {
      rules,
      error,
      name,
      invalid,
      label,
      type,
      prefix,
      suffix,
      rounded,
      noMargin,
      margin,
      button,
      searchField,
      onlyRead,
      labelIcon,
      disabled,
      onClickSuffix,
      datePicker,
      clear,
      invert,
      labelReverse,
      radioBorder,
      labelColor,
      ...props
    },
    ref
  ) => {
    const inputProps = {
      id: props.id ?? name,
      name,
      type,
      invalid,
      "aria-describedby": `${name}Error`,
      ...props,
    };
    const renderInputFirst = type === "checkbox" || type === "radio";
    return (
      <StyledFormGroup
        $invalid={invalid || error}
        noMargin={noMargin}
        css={`
          margin-bottom: ${margin};
        `}
      >
        {renderInputFirst || (
          <>
            {label && (
              <Label
                invert={invert}
                onClear={() =>
                  inputProps?.onChange?.({
                    target: {
                      name,
                      value: type === "datepicker" ? [null, null] : "",
                    },
                  })
                }
                clear={clear}
                labelIcon={labelIcon}
                htmlFor={inputProps.id}
                required={rules?.filter(({ required }) => required).length}
              >
                {label}
              </Label>
            )}
            <InputHolder $searchField={searchField}>
              <>
                {/* any other input type */}
                <Input
                  ref={ref}
                  {...inputProps}
                  $prefix={prefix}
                  disabled={disabled}
                  $suffix={suffix}
                  $invalid={invalid || error}
                  $rounded={rounded}
                  $button={button && true}
                />
              </>
            </InputHolder>
          </>
        )}
        {invalid ||
          (error && (
            <Error id={`${name}Error`} role="alert">
              {error}
            </Error>
          ))}
      </StyledFormGroup>
    );
  }
);

Field.defaultProps = defaultProps;
Field.displayName = "Field";

export default Field;
