import styled, { createGlobalStyle, css } from "styled-components";
import Variables from "../styles/variables.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// prettier-ignore
const Styling = css`
  /* theme css variables */
  ${Variables}

  /* Other Reset that aren't define in normalize.css*/
  html {
    box-sizing: border-box;
    font-size: 100%;
    scroll-behavior: smooth;
  }

  *,
  *:before,
  *:after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  body {
    font: var(--font-size-base) / var(--line-height-base)
      var(--base-font-family);
    color: var(--base-text-color);
    background: var(--base-background-color);
    position: relative;
    min-width: var(--base-min-width);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    &.nav-active {
      overflow: hidden;
    }

    &:before {
      display: none;
      content: "";
      position: absolute;
      top: 0;
      bottom: -10%;
      left: 0;
      right: 0;
      backdrop-filter: blur(4px);
      background: rgba(50, 59, 75, 0.3);
      z-index: 9;
    }
  }

  .container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 15px;
  }
  .confirmation {
    margin-top: 50px;
    font-size: 16px;
    line-height: 20px;
    @media screen and (min-width: 768px) {
      font-size: 18px;
      line-height: 22px;
    }
    .btnWrapp {
      display: flex;
      gap: 10px;
      button {
        width: 100%;
      }
      @media screen and (max-width: 576px) {
        flex-direction: column;
      }
    }
  }
  figure {
    margin: 0;
  }
  img {
    display: block;
    max-width: 100%;
    height: auto;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  textarea {
    resize: vertical;
    vertical-align: top;
  }

  button,
  input[type="button"],
  input[type="reset"],
  input[type="file"],
  input[type="submit"] {
    cursor: pointer;
  }

  form,
  fieldset {
    margin: 0;
    padding: 0;
    border-style: none;
  }
  a {
    text-decoration: none;
  }

  input[type="search"]::-webkit-search-decoration,
  input[type="search"]::-webkit-search-cancel-button,
  input[type="search"]::-webkit-search-results-button,
  input[type="search"]::-webkit-search-results-decoration {
    display: none;
  }

  a,
  button {
    transition: opacity var(--animation-speed) ease-in-out,
      background var(--animation-speed) ease-in-out,
      visibility var(--animation-speed) ease-in-out,
      border var(--animation-speed) ease-in-out,
      color var(--animation-speed) ease-in-out;
  }

  button {
    padding: 0;
    border: none;
    background: none;
    outline: none;
  }

  br {
    @media (max-width: 767px) {
      display: none;
    }
  }

  h1,
  .h1,
  h2,
  .h2,
  h3,
  .h3,
  h4,
  .h4,
  h5,
  .h5,
  h6,
  .h6 {
    margin: 0 0 10px;
  }

  h1,
  .h1 {
    font-size: 36px;
    line-height: 42px;
    font-weight: 600;
    text-transform: capitalize;

    @media (min-width: 768px) {
      font-size: 45px;
      line-height: 50px;
    }
    @media (min-width: 992px) {
      font-size: 55px;
      line-height: 60px;
    }
    @media (min-width: 1200px) {
      font-size: 64px;
      line-height: 70px;
    }

    span {
      color: var(--blue);
    }
  }
  h2,
  .h2 {
    font-size: 26px;
    line-height: 31px;
    font-weight: 500;
    text-transform: capitalize;

    @media (min-width: 768px) {
      font-size: 30px;
      line-height: 34px;
    }
    @media (min-width: 992px) {
      font-size: 34px;
      line-height: 38px;
    }
    @media (min-width: 1200px) {
      font-size: 38px;
      line-height: 42px;
    }
  }

  p {
    margin: 0 0 15px;

    &:last-child {
      margin: 0;
    }
  }

  /************* custom scrollbar styles ************/

  /* This will work on Firefox */
  * {
    scrollbar-width: thin;
    scrollbar-color: var(--blue) #eceaf9;
  }

  /* Targtes on Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  *::-webkit-scrollbar-track {
    border-radius: 30px;
    background: #eceaf9;
  }

  *::-webkit-scrollbar-thumb {
    background: var(--primary);
    box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.03);
    border-radius: 30px;
  }

  /* Remove Arrows/Spinners from input type number */

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    appearance: textfield;
  }
`;

const GlobalStyles = createGlobalStyle`
  ${Styling}
`;
export const StyledToastContainer = styled(ToastContainer)`
  z-index: 99999;

  .Toastify__toast {
    padding: 0;
    min-height: 0;
    border-radius: 8px;
    font-family: inherit;
  }
  .Toastify__toast--default {
    background: none;
  }
  .Toastify__toast-body {
    padding: 0;
  }
  .Toastify__close-button {
    position: absolute;
    top: 50%;
    right: 8px;
    transform: translateY(-50%);
  }
`;
export default GlobalStyles;
