import React from "react";
import Button from "../../Button";
import { useNavigate } from "react-router-dom";
import { StyledOfferModal } from "./Offer.styles";
import rocket from "../../../assets/images/icon-rocket.svg";

const OfferModal = ({setOpen}) => {
  const navigate = useNavigate();

  return (
    <StyledOfferModal>
      <p>
      Grib chancen i april - Få dit kassesystem, 100% gratis. I hele Oktober 2024 tilbyder vi en unik chance for at revolutionere din virksomhed med vores avancerede kassesystem - helt gratis. Ja, du hørte rigtigt: GRATIS!
      </p>
      <p>
      Tag springet og oplev forskellen ved at prøve vores kassesystem gratis i 30 dage. Ingen bindinger, ingen skjulte gebyrer, ingen betaling af nogen art. Hvis du beslutter dig for at fortsætte efter prøveperioden, kan du gøre dette til en fast lav månedlig pris på blot 399 kr. - uden merpris.
      </p>
      <p>
      I denne særlige kampagneperiode får du mulighed for at spare hele 20.000 kr., hvilket svarer til systemets fulde værdi. Lad ikke denne utrolige mulighed slippe igennem dine hænder. Opdag, hvordan vores kassesystem kan gøre en forskel for din virksomhed allerede i dag!
      </p>
      <Button
        onClick={() => {
          navigate("/order-checkout");
          setOpen(false);
        }}
      >
        Bestil et gratis kassesystem! <img src={rocket} alt="rocket" />
      </Button>
    </StyledOfferModal>
  );
};

export default OfferModal;
