import styled from "styled-components";

export const StyledCard = styled.div`
  width: 100%;
  padding: 20px;
  text-transform: capitalize;
  border: 1px solid rgba(205, 205, 205, 0.5);
  border-radius: 20px;
  background: var(--White, #fff);
  box-shadow: 0px 132px 37px 0px rgba(0, 0, 0, 0),
    0px 84px 34px 0px rgba(0, 0, 0, 0.01), 0px 47px 28px 0px rgba(0, 0, 0, 0.03),
    0px 21px 21px 0px rgba(0, 0, 0, 0.04), 0px 5px 12px 0px rgba(0, 0, 0, 0.05);
  position: relative;

  &:not(:last-child) {
    margin-bottom: 20px;
  }

  .head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 35px;
    margin-bottom: 8px;

    .btn {
      padding: 10px 16px;
      background: #ebecf5;
      border-radius: 60px;
      font-size: 14px;
      line-height: 18px;
      color: var(--blue);
    }
  }

  .title,
  .price {
    display: block;
    font-size: 26px;
    line-height: 30px;
    font-weight: 500;
  }
  .radio {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 20px;
    height: 20px;
    border: 1px solid var(--blue);
    border-radius: 50%;

    &::before {
      content: "";
      position: absolute;
      top: 1px;
      left: 1px;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      background: var(--blue);
      opacity: ${({ $radio }) => ($radio ? "1" : "0")};
      transition: all 0.3s ease-in-out;
    }
  }
  .text {
    display: block;
    margin-bottom: 10px;
    @media (min-width: 576px) {
      margin-bottom: 15px;
    }
  }
  .content {
    display: block;
    font-size: 14px;
    line-height: 18px;

    @media (min-width: 576px) {
      display: flex;
      justify-content: space-between;
      gap: 24px;
    }

    p {
      width: 100%;
      max-width: 312px;
      margin-bottom: 10px;
      @media (min-width: 576px) {
        margin: 0;
      }
    }

    .price-holder {
      width: 100%;
      max-width: 170px;
      text-align: center;
      margin: 0 auto;
      @media (min-width: 576px) {
        text-align: right;
        margin: 0;
      }
      @media (min-width: 768px) {
        max-width: 240px;
      }

      .price {
        font-weight: 600;
        margin-bottom: 5px;
      }
      .price-text {
        display: block;
        font-size: 11px;
        line-height: 15px;
      }
    }
  }
`;
