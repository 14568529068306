import React from "react";
import Card from "./Card";
import { StyledOurPlans } from "./OurPlans.styles";
import { useState } from "react";
import checkMark from "../../../src/assets/images/icon-tick.svg";
import btnIcon from "../../../src/assets/images/button-icon.png";
import Button from "../Button";
import { Link } from "react-router-dom";

const OurPlans = () => {
  const [radio, setRadio] = useState(0);
  const cardsData = [
    {
      title: "Hardware",
      text: "alt-i-et kassesystem",
      para: "Vi leverer og installerer al den hardware, du skal bruge for at begynde at modtage betalinger fra dine kunder.",
      price: "Kr. 20.00",
      priceText: "Betales ved levering (moms tillægges prisen).",
    },
    {
      title: "Software",
      btnText: "spar %1,49",
      text: "PayBliss betalingsløsning",
      para: "Vi leverer og installerer al den hardware, du skal bruge for at begynde at modtage betalinger fra dine kunder.",
      price: "Kr. 399",
      priceText: "12 måneders binding (moms tillægges prisen).",
    },
  ];
  const listData = [
    {
      text: '15,6" berøringsskærm ',
    },
    {
      text: "Integreret NFC",
    },
    {
      text: "Kasseskuffe",
    },
    {
      text: "Kvitteringsprinter",
    },
    {
      text: "Håndscanner",
    },
    {
      text: "Leveret og monteret",
    },
    {
      text: "Opsætning",
    },
    {
      text: "24/7 teknisk support",
    },
  ];
  return (
    <StyledOurPlans>
      <div className="container">
        <h2>Vores planer!</h2>
        <span className="sub-heading">Ingen overraskelsesgebyrer</span>
        <div className="cards-holder">
          <div className="cards">
            {cardsData?.map((item, index) => (
              <Card
                key={index}
                radio={radio === index}
                onClick={() => setRadio(index)}
                title={item?.title}
                btnText={item?.btnText}
                text={item?.text}
                paragraph={item?.para}
                price={item?.price}
                priceText={item?.priceText}
              />
            ))}
          </div>
          <div className="specifications">
            {listData?.map((item, index) => (
              <div key={index} className="list">
                <span>{item?.text}</span>
                <div className="img-holder">
                  <img src={checkMark} alt="checkMark" width={21} height={20} />
                </div>
              </div>
            ))}
          </div>
        </div>
        <Link to="/order-checkout" className="button">
          <Button width="200">
            Køb Plan! <img src={btnIcon} alt="btnIcon" />
          </Button>
        </Link>
      </div>
    </StyledOurPlans>
  );
};

export default OurPlans;
