import React, { useEffect, useState } from "react";
import { ContactStyles } from "./Offer/Offer.styles";
import tick from "../../assets/images/icon-tick.svg";
import rocket from "../../assets/images/icon-rocket.svg";
import Field from "../Field";
import Form, { useForm } from "../../components/Form";
import Button from "../Button";
import Toast from "../Toast";
import CenterModal from "../Modal/CenterModal";
import { isValidPhoneNumber } from "react-phone-number-input";
const Contact = () => {
  const [form] = useForm();
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [modal, setModal] = useState(false);
  const [data, setData] = useState({});
  const handleSubmit = async (e) => {
    const obj = { ...e, alreadyApplied: false };
    console.log(obj);
    setData(e);
    document.getElementById("submit-btn").setAttribute("disabled", true);
    // setLoading(true);
    try {
      const response = await fetch(
        "https://business.payblisss.com/api/subscribe/create-subscriber",
        // "http://localhost:4001/api/subscribe/create-subscriber",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(obj),
        }
      );
      if (response.status === 409) {
        setModal(true);
        form.setFieldsValue({
          company_name: "",
          crv_number: "",
          name: "",
          email: "",
          phone: "",
          reference_number: "",
          daily_turnover: "",
        });
        form.setFieldsError({
          company_name: { message: "" },
          crv_number: { message: "" },
          name: { message: "" },
          email: { message: "" },
          phone: { message: "" },
          reference_number: { message: "" },
          daily_turnover: { message: "" },
        });
      } else {
        Toast({
          type: "success",
          message:
            "Din ordre er modtaget, du vil snart modtage en bekræftelsesmail.",
        });
        form.setFieldsValue({
          company_name: "",
          crv_number: "",
          name: "",
          email: "",
          phone: "",
          reference_number: "",
          daily_turnover: "",
        });
        form.setFieldsError({
          company_name: { message: "" },
          crv_number: { message: "" },
          name: { message: "" },
          email: { message: "" },
          phone: { message: "" },
          reference_number: { message: "" },
          daily_turnover: { message: "" },
        });
      }
      if (response.status === 500) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Network response was not ok");
      }
    } catch (error) {
      Toast({ type: "error", message: error.message });
    } finally {
      // setLoading(false);
      document.getElementById("submit-btn").setAttribute("disabled", false);
    }
  };
  async function AppliedAgian() {
    setLoading2(true);
    const obj = { ...data, alreadyApplied: true };
    try {
      const response = await fetch(
        "https://business.payblisss.com/api/subscribe/create-subscriber",
        // "http://localhost:4001/api/subscribe/create-subscriber",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(obj),
        }
      );
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Network response was not ok");
      }
      Toast({
        type: "success",
        message:
          "Din ordre er modtaget, du vil snart modtage en bekræftelsesmail.",
      });
      setModal(false);
    } catch (error) {
      Toast({ type: "error", message: error.message });
    } finally {
      setLoading2(false);
    }
  }
  return (
    <>
      <CenterModal open={modal} setOpen={setModal} width={550} title={" "}>
        <div className="confirmation">
          <p>
            Vi har tidligere modtaget en ordre fra denne e-mailadresse. Vil du
            afgive en anden ordre?
          </p>
          <div className="btnWrapp">
            <Button
              onClick={AppliedAgian}
              disabled={loading2}
              loader={loading2}
            >
              Yes
            </Button>
            <Button outline onClick={() => setModal(false)}>
              No
            </Button>
          </div>
        </div>
      </CenterModal>
      <ContactStyles>
        <div className="container">
          <div className="textWrapper">
            <div className="col">
              <strong className="title">PayBliss's alt-i-én kassesystem</strong>
              <div className="included">
                <img src={tick} alt="tick" />
                <p>30 dages prøveperiode</p>
              </div>
              <div className="included">
                <img src={tick} alt="tick" />
                <p>Al hardware og software</p>
              </div>
              <div className="included">
                <img src={tick} alt="tick" />
                <p>
                  Helt gratis kassesystem (KAMPAGNE fra april til oktober 2024)
                </p>
              </div>
              <div className="included">
                <img src={tick} alt="tick" />
                <p>Vi sætter det hele op for dig</p>
              </div>
              <div className="included">
                <img src={tick} alt="tick" />
                <p>Efter 30 dage kun 399 kr om måneden</p>
              </div>
            </div>
            <div className="col">
              <strong className="title">Hvad forpligter jeg mig til?</strong>
              <p>
                Vi kører kampagnetilbud fra april til oktober. Det betyder, at
                du kan få kassesystemet helt gratis!
              </p>
              <p>
                Du kan prøve kasseapparatet i 30 dage og vil du beholde det
                efterfølgende, skal du kun betale det månedlige abonnement på
                399 kr.
              </p>
            </div>
            <div className="col">
              <strong className="title">Hvad sker der efter ordren?</strong>
              <p>
                Når vi modtager din ordre, modtager du en ordrebekræftelse på
                mail, med information om hvornår du kan forvente, at vi opsætter
                kassesystemet i din virksomhed.
              </p>
              <p>
                Husk: Kassesystemet er helt gratis – også selvom du vælger at
                beholde det efter prøveperiodens udløb.
              </p>
            </div>
          </div>
          <div className="formWrapper">
            <Form form={form} onSubmit={(e) => handleSubmit(e)}>
              <Form.Item
                type="text"
                label="Firmanavn"
                name="company_name"
                rounded
                placeholder="Indtast tekst..."
                maxLength={256}
                rules={[
                  {
                    required: true,
                    message: "Indtast venligst firmanavn",
                  },
                  {
                    pattern: /^[a-zA-Z0-9_-]{5,25}$/,
                    message: "Indtast venligst et navn på mellem 5 og 25 tegn",
                  },
                ]}
              >
                <Field />
              </Form.Item>
              <Form.Item
                type="text"
                label="CVR nummer"
                name="crv_number"
                rounded
                placeholder="Indtast cvr nummer..."
                maxLength={256}
                rules={[
                  {
                    required: true,
                    message: "Indtast cvr nummer",
                  },
                  {
                    pattern: /^[0-9]{5,10}$/,
                    message:
                      "Indtast venligst et navn mellem 5 og 10 tegn (CVR skal kun indeholde tal)",
                  },
                ]}
              >
                <Field />
              </Form.Item>
              <Form.Item
                type="text"
                label="Dit navn"
                name="name"
                rounded
                placeholder="Indtast navn..."
                maxLength={256}
                rules={[
                  {
                    required: true,
                    message: "Indtast navn",
                  },
                  {
                    pattern: /^[a-zA-ZæøåÆØÅ]{3,20}$/,
                    message:
                      "Navn skal være mellem 3 og 20 tegn (navn må kun indeholde bogstaver)",
                  },
                ]}
              >
                <Field />
              </Form.Item>
              <Form.Item
                type="email"
                label="Din mail adresse"
                name="email"
                sm
                rounded
                placeholder="alex123@gmail.com"
                rules={[
                  {
                    required: true,
                    message: "Indtast venligst e-mailadresse",
                  },
                  {
                    pattern:
                      /^[a-zA-Z0-9%+-]+(\.[a-zA-Z0-9%+-]+)?@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,

                    message: "Indtast venligst en gyldig e-mailadresse",
                  },
                ]}
              >
                <Field maxLength={40} />
              </Form.Item>
              <Form.Item
                type="number"
                label="Dit telefonnummer"
                name="phone"
                rounded
                placeholder="Indtast Dit telefonnummer..."
                maxLength={256}
                rules={[
                  {
                    required: true,
                    message: "telefonnummer påkrævet",
                  },
                  {
                    pattern:
                      /^(?:\+?\d{1,4}[ -]?)?\(?\d{1,4}\)?[ -]?\d{1,4}[ -]?\d{1,9}$/,
                    message: "Indtast venligst et gyldigt telefonnummer.",
                  },
                  // {
                  //   transform: (value) => {
                  //     let strValue = value;
                  //     if (typeof value !== "string") strValue = String(value);
                  //     return !isValidPhoneNumber(strValue);
                  //   },
                  //   message: "Indtast venligst et gyldigt telefonnummer.",
                  // },
                ]}
              >
                <Field />
              </Form.Item>
              <Form.Item
                type="text"
                label="Referencenummer"
                name="reference_number"
                rounded
                placeholder="Indtast  Referencenummer..."
                maxLength={256}
                rules={[
                  {
                    required: true,
                    message: "Referencenummer er påkrævet",
                  },
                  {
                    pattern: /^\d{4}$/,
                    message:
                      "Indtast venligst et gyldigt referencenummer (skal være 4 cifre).",
                  },
                ]}
              >
                <Field />
              </Form.Item>
              <Form.Item
                type="text"
                label="Daglig omsætning"
                name="daily_turnover"
                rounded
                placeholder="Ex. 10000"
                maxLength={256}
                rules={[
                  {
                    required: true,
                    message: "Daglig omsætning er påkrævet",
                  },
                ]}
              >
                <Field />
              </Form.Item>
              <Button loader={loading} id="submit-btn">
                Bestil nu! <img src={rocket} alt="rocket" />
              </Button>
            </Form>
            <span className="privacy">
              Når du afgiver ordren, giver du Paybliss ret til at gemme dine
              indtastede oplysninger, og at vores salgskonsulenter kan kontakte
              dig.
            </span>
          </div>
        </div>
      </ContactStyles>
    </>
  );
};

export default Contact;
