import styled from "styled-components";

export const TextHolderStyle = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  padding: 40px 0;
  background: var(--white);

  @media (min-width: 992px) {
    padding: 60px 0;
  }
  @media (min-width: 1200px) {
    padding: 80px 0;
  }

  .img-holder {
    max-width: 650px;
    margin: 0 auto;

    @media (min-width: 992px) {
      max-width: 800px;
    }
    @media (min-width: 1200px) {
      max-width: 1000px;
    }
    @media (min-width: 1440px) {
      max-width: 1200px;
    }
  }
`;
