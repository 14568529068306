import styled from "styled-components";

export const StyledFooter = styled.section`
  background: #ebfaff;
  padding: 30px 0;

  @media (min-width: 576px) {
    padding: 50px 0;
  }

  .container {
    display: flex;
    justify-content: space-between;
    flex-flow: wrap;
    gap: 30px;

    @media (min-width: 576px) {
      gap: 50px;
    }

    @media (min-width: 992px) {
      gap: 30px;
      flex-flow: nowrap;
    }
  }
  .heading {
    display: block;
    font-size: 20px;
    line-height: 24px;
    font-weight: 500;
    margin-bottom: 20px;
  }
  .contact-info {
    width: 100%;
    max-width: 340px;
    .logo-holder {
      max-width: 150px;
      margin-bottom: 15px;
      @media (min-width: 768px) {
        max-width: 200px;
        margin-bottom: 30px;
      }
      img {
        max-width: 100%;
        height: auto;
        display: block;
      }
    }
    address {
      font-style: normal;
      font-size: 18px;
      line-height: 22px;
      margin-bottom: 15px;
      @media (min-width: 768px) {
        margin-bottom: 30px;
        font-size: 20px;
        line-height: 24px;
      }
    }

    .mail,
    .whatsApp,
    .links-holder {
      display: flex;
      align-items: center;
      gap: 10px;
      color: var(--gray-dark);
    }

    .links-holder {
      padding-top: 10px;
      gap: 20px;
      @media (min-width: 576px) {
        padding-top: 20px;
      }
    }
  }
  .policies {
    ul {
      li {
        cursor: pointer;
        color: var(--gray-dark);
        margin-bottom: 10px;
        transition: 0.3s;
        &:hover {
          transform: translateX(10px);
          color: var(--blue);
          font-weight: 400;
        }

        a {
          color: var(--gray-dark);
        }
      }
    }
  }
  .contact-us {
    width: 100%;
    @media (min-width: 768px) {
      max-width: 350px;
    }
    @media (min-width: 1200px) {
      max-width: 450px;
    }
    p {
      margin-bottom: 20px;
    }
    .search-bar {
      background: #d9e8f5;
      border-radius: 60px;
      position: relative;
      input {
        width: 100%;
        height: 55px;
        padding: 20px 130px 20px 20px;
        background: none;
        border: none;
        outline: none;
      }
      button {
        position: absolute;
        top: 5px;
        right: 5px;
        background-color: var(--blue);
        color: var(--white);
        max-width: 120px;
        padding: 15px 30px;
        border-radius: 60px;
      }
    }
  }
`;
