import React from "react";
import { StyledHeroSection } from "./HeroSection.styles";

const HeroSection = () => {
  return (
    <StyledHeroSection>
      <div className="container">
        <h1>
          Bestil<span> kassesystem!</span>
        </h1>
        <p>
          Ønsker du vores kassesystem hurtigst muligt, anbefaler vi, at du
          afgiver en ordre med det samme for at komme forrest i køen. Du
          forpligter dig ikke til noget, før vi er helt enige med hinanden.
        </p>
        <div className="message">
          Da vi i øjeblikket modtager et ekstraordinært antal ordrer, du skal
          regne med en leveringstid på op til 1,5 måned.
        </div>
      </div>
    </StyledHeroSection>
  );
};

export default HeroSection;
