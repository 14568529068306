import React from "react";
import { StyledCard } from "./Card.styles";

const Card = ({
  title,
  btnText,
  text,
  paragraph,
  price,
  priceText,
  radio,
  onClick,
}) => {
  return (
    <StyledCard $radio={radio} onClick={onClick}>
      <div className="radio" />
      <div className="head">
        <strong className="title">{title}</strong>
        {btnText && <div className="btn">{btnText}</div>}
      </div>
      <span className="text">{text}</span>
      <div className="content">
        <p>{paragraph}</p>
        <div className="price-holder">
          <strong className="price">{price}</strong>
          <span className="price-text">{priceText}</span>
        </div>
      </div>
    </StyledCard>
  );
};

export default Card;
