import styled from "styled-components";

export const StyledHeroSection = styled.div`
  background: linear-gradient(160.66deg, #ebfaff 46.25%, #e6e4ff 128.26%);
  padding: 140px 0 167px;
  .container {
    max-width: 890px;
    font-size: 18px;
    line-height: 22px;
    font-weight: 300;
    text-align: center;
    @media screen and (min-width: 991px) {
      font-size: 22px;
      line-height: 26px;
    }
  }
  h1 {
    margin-bottom: 26px;
  }
  p {
    margin-bottom: 26px;
  }
  .message {
    max-width: 587px;
    width: 100%;
    margin: 0 auto;
    background: #36469a1a;
    font-size: 16px;
    line-height: 20px;
    font-weight: 300;
    padding: 14px 26px;
    gap: 10px;
    border-radius: 20px;
    @media screen and (min-width: 991px) {
      font-size: 18px;
      line-height: 22px;
      border-radius: 60px;
    }
  }
`;
