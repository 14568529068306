import React, { useEffect, useState } from "react";
import { StyledFooter } from "./Footer.styles";
import logo from "../../../src/assets/images/footer-logo.png";
import { TbMailFilled } from "react-icons/tb";
import { RiWhatsappFill } from "react-icons/ri";
import { Link } from "react-router-dom";
import CenterModal from "../Modal/CenterModal";
import PrivacyPloicyModal from "../PrivacyPolicy";
import CookiePolicyModal from "../CookiePolicy";
import ConditionPloicyModal from "../ConditionPolicy";
import Toast from "../Toast";
const Footer = () => {
  const number = "+4543113544";
  const [privacyModal, setPrivacyModal] = useState(false);
  const [cookieModal, setcookieModal] = useState(false);
  const [conditionModal, setConditionModal] = useState(false);
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);

  const handleInputChange = (event) => {
    setEmail(event.target.value);
  };

  const handleSubmit = async () => {
    setLoading(true);

    try {
      const response = await fetch(
        "https://business.payblisss.com/api/subscribe/subscriber",
        //"http://localhost:4001/api/subscribe/subscriber",

        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email }),
        }
      );

      if (response.status === 409 || response.status === 500) {
        Toast({
          type: "error",
          message: "Der opstod en fejl under indsendelse af formularen",
        });
      }

      if (response.status === 400) {
        Toast({
          type: "error",
          message: "Allerede abonneret",
        });
      }
      if (response.status === 200) {
        Toast({
          type: "success",
          message: "Tilmeldt med succes.",
        });
      }
    } catch (error) {
      console.log(error);
      Toast({
        type: "error",
        message: "An error occurred while submitting the form",
      });
    } finally {
      setEmail("");
      setLoading(false);
    }
  };

  return (
    <>
      {/* Privacy policy modal */}
      <CenterModal
        open={privacyModal}
        setOpen={setPrivacyModal}
        title={<h2>Fortrolighedspolitik for PayBlisss</h2>}
        width={1170}
      >
        <PrivacyPloicyModal setOpen={setPrivacyModal} />
      </CenterModal>

      {/* cookies modal */}
      <CenterModal
        open={cookieModal}
        setOpen={setcookieModal}
        title={<h2>Cookiepolitik for PayBlisss</h2>}
        width={1170}
      >
        <CookiePolicyModal setOpen={setcookieModal} />
      </CenterModal>

      {/* condition modal */}
      <CenterModal
        open={conditionModal}
        setOpen={setConditionModal}
        title={<h2>Vilkår og Betingelser for PayBlisss</h2>}
        width={1170}
      >
        <ConditionPloicyModal setOpen={setConditionModal} />
      </CenterModal>

      <StyledFooter>
        <div className="container">
          <div className="contact-info">
            <div className="logo-holder">
              <img src={logo} alt="logo" />
            </div>
            <address>Danmarks mest brugervenlige alt-i-et kassesystem.</address>
            <Link to="mailto:kundeservice@payblisss.com" className="mail">
              <TbMailFilled />
              <span>kundeservice@payblisss.com</span>
            </Link>
            <div className="links-holder">
              <Link to="mailto:salg@payblisss.com" className="mail">
                <TbMailFilled />
                <span>salg@payblisss.com</span>
              </Link>
              <Link
                to={`https://wa.me/${number}`}
                className="whatsApp"
                target="blank"
              >
                <RiWhatsappFill />
                <span>+45 43 11 35 44</span>
              </Link>
            </div>
          </div>
          <div className="policies">
            <span className="heading">Politikker</span>
            <ul>
              <li>
                {/* <Link>Fortrolighedspolitik</Link> */}
                <li onClick={() => setPrivacyModal(true)}>
                  Fortrolighedspolitik
                </li>
              </li>
              {/* <li>
                <Link>Cookiepolitik</Link>
              </li> */}
              <li onClick={() => setcookieModal(true)}>Cookiepolitik</li>
              <li>
                {/* <Link>Vilkår og Betingelser</Link> */}
                <li onClick={() => setConditionModal(true)}>
                  Vilkår og Betingelser
                </li>
              </li>
            </ul>
          </div>
          <div className="contact-us">
            <span className="heading">Kontakt os</span>
            <p>
              Uanset om du har et spørgsmål om vores tjenester, har brug for
              assistance eller blot ønsker at give feedback, er du velkommen til
              at kontakte os.
            </p>
            <div className="search-bar">
              <input
                placeholder="Enter Email Address"
                value={email}
                onChange={handleInputChange}
              />
              <button onClick={handleSubmit} disabled={loading}>
                {"Subscribe"}
              </button>
            </div>
          </div>
        </div>
      </StyledFooter>
    </>
  );
};

export default Footer;
