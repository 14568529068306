import React, { useEffect, useState } from "react";
import { StyledHeader } from "./Header.styles";
import Logo from "../../assets/images/PayBlisssLogo.svg";
import cart from "../../assets/images/cart.svg";
import rocket from "../../assets/images/icon-rocket.svg";
import { Link, NavLink } from "react-router-dom";
import { RiWhatsappFill } from "react-icons/ri";
import Button from "../Button";
import { HiOutlineMenuAlt3 } from "react-icons/hi";
import { IoClose } from "react-icons/io5";
import CenterModal from "../Modal/CenterModal";
import { useNavigate } from "react-router-dom";
import { StyledOfferModal } from "../Checkout/Offer/Offer.styles";
import OfferModal from "../Checkout/Offer/OfferModal";

const Header = () => {
  const number = "+4543113544";
  const [showBg, setShowBg] = useState(false);
  const [navbar, setNavbar] = useState(false);
  const [modal, setModal] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    function handleScroll() {
      // Check the scroll position to determine if the shadow should be shown
      if (window.scrollY > 20) {
        setShowBg(true);
      } else {
        setShowBg(false);
      }
    }
    handleScroll();
    // Attach the scroll event listener when the component mounts
    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <>
      <CenterModal
        open={modal}
        setOpen={setModal}
        title={
          <h2>
            Kampagnetilbud fra april til oktober: Få dit kassesystem
            <br />
            helt gratis!
          </h2>
        }
        width={1170}
      >
        <OfferModal setOpen={setModal} />
      </CenterModal>
      <StyledHeader $bg={showBg} $showNav={navbar}>
        <div className="container">
          <Link to="/" className="logoWrapper">
            <figure>
              <img src={Logo} alt="logo" />
            </figure>
          </Link>
          <div className="NavLinkWrapper">
            <ul>
              <li>
                <NavLink to={"/"}>Hjem</NavLink>
              </li>
              <li>
                <NavLink to={"/Lovkrav"}>Lovkrav</NavLink>
              </li>
              <li>
                <NavLink to={"/alt-i-en"}>Alt i en</NavLink>
              </li>
              <li onClick={() => setModal(true)}>Priser</li>
            </ul>
            <div className="Cta">
              <Link
                to={`https://wa.me/${number}`}
                className="whatsApp"
                target="blank"
              >
                <RiWhatsappFill size={18} />
                WhatsApp
              </Link>
              <Link to="/order-checkout">
                <Button>
                  <img src={cart} alt="cart" />
                  Bestil kassesystem
                </Button>
              </Link>
            </div>
          </div>
          <div className="hamburger" onClick={() => setNavbar(!navbar)}>
            {!navbar ? <HiOutlineMenuAlt3 /> : <IoClose />}
          </div>
        </div>
      </StyledHeader>
    </>
  );
};

export default Header;
